import fonoFrancisApresentacao from '../../assets/images/foto-francis-cortada.jpg'
import styles from './PaginaApresentacao.module.scss'
import cx from 'classnames'

const PaginaApresentacao = () => (
  <section className={cx(styles.pageCard, styles.backgroundNotebook)}>
    <section className={styles.firstBlock}>
      <section className={styles.text}>
        <h1>
          Atendimento fonoaudiológico para crianças e adultos em qualquer lugar do mundo!
        </h1>
        
        <div className={styles.imagemComAssinatura}>
          <div className={styles.portrait}>
            <img src={fonoFrancisApresentacao} />
          </div>
        </div>
        <p>
          Nossa equipe está pronta para cuidar da sua comunicação. <br />
          Agende agora!
        </p>
      </section>
{/*         <div className={styles.nomeDaFono}>
          <p>
            Fonoaudióloga
          </p>
          <p>
            Francis Guimarães
          </p>
          <p>
            CRFa 8- 12521
          </p>
        </div> */}
        
{/*         <img src={doubleArrowDown} alt="Menu Bar" /> */}
    </section>
    <section className={styles.secondBlock}>
    <iframe width="600" height="380"
      src="https://www.youtube.com/embed/BdSosOWeVpE?si=yB3OPBMeuPK4w7cr">
    </iframe> 
    </section>
  </section>
)

export default PaginaApresentacao