import { ReactNode } from "react"
import SideBar from "../DS/SideBar/SideBar"
import styles from './ContainerWithSideAndUpperBar.module.scss'
import UpperBar from "../DS/UpperBar/UpperBar"

type Props = {
    children: ReactNode
}

const ContainerWithSideAndUpperBar = ({ children }: Props) => {
    return (
        <div className={styles.container}>
            <SideBar />
            <section className={styles.bodyAndUpperBarContainer}>
                <UpperBar />
                {children}
            </section>
        </div>
    )
}

export default ContainerWithSideAndUpperBar