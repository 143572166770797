import ReactDOM from 'react-dom/client'
import './index.css'
import LandingPage from './LandingPage'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Login from './core/Login/Login'
import axios from 'axios'
import 'material-icons/iconfont/material-icons.css'
import DashboardContainer from './core/Dashboard/DashboardContainer'

axios.defaults.baseURL = 'http://localhost:8000'
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      const currentPath = window.location.pathname
      if (currentPath !== '/login') {
        localStorage.removeItem('token')
        window.location.href = '/login'
      }
    }
    return Promise.reject(error)
  }
)

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<DashboardContainer />} />
      <Route path="*" element={<Navigate to='/' replace />} />
    </Routes>
  </BrowserRouter>
);
