import ContainerWithSideAndUpperBar from "../Container/ContainerWithSideAndUpperBar"
import styles from './DashboardContainer.module.scss'

const DashboardContainer = () => {
    return (
        <>
            <ContainerWithSideAndUpperBar>
                <section className={styles.container}>
                    <h2>DASHBOARD</h2>
                </section>
            </ContainerWithSideAndUpperBar>
        </>
    )
}

export default DashboardContainer