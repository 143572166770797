import Module from './Module/Module'
import styles from './SideBar.module.scss'

const SideBar = () => {
    return (
        <div className={styles.container}>
            <div className={styles.banner}>
                <span>
                    Minha Fono Online
                </span>
            </div>
            <Module name="DashBoard" iconName='bar_chart'/>
            <Module name="Pacientes" iconName='child_care'/>
            <Module name="Contabilidade" iconName='attach_money'/>
        </div>
    )
}

export default SideBar