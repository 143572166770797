import Button from '../DS/Button/Button'
import Input from '../DS/Input/Input'
import styles from './Login.module.scss'
import {submitLogin} from '../gateway/loginGateway'
import { Controller, useForm } from 'react-hook-form'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate();
  const methods = useForm({ 
    reValidateMode: 'onSubmit' ,
    defaultValues: {
      email: "",
      senha: "",
    }
  })
  const { handleSubmit, control, formState: { errors } } = methods
  const [usuarioOuSenhaInvalidos, setUsuarioOuSenhaInvalidos] = useState<Boolean>(false)

  const submitForm = (data: { email: string, senha: string }) => {
    if (!errors.email && !errors.senha) {
      submitLogin(data.email, data.senha).then(
        response => {
          localStorage.setItem('token', response.data.access_token)
          navigate('/dashboard')
        }
      ).catch(() => {
        setUsuarioOuSenhaInvalidos(true)
      })
    }
  }


  return (
    <div className={styles.container}>
      <form className={styles.containerInputs} onSubmit={handleSubmit(submitForm)}>
        <Controller
          control={control}
          name="email"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input tipo='text' placeholder='email' onChange={onChange} value={value}/>
          )}
        />
        {errors.email && <span>This is required.</span>}
        <Controller
          control={control}
          name="senha"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input tipo='password' placeholder='senha' onChange={onChange} value={value}/>
          )}
        />
        <Button onClick={() => {}} type='submit'>enviar</Button>
        {usuarioOuSenhaInvalidos && (<span className={styles.errorMessage}>Usuário e/ou senha inválidos!</span>)}
      </form>
    </div>
  )
}

export default Login