import styles from './PaginaAreasAtuacao.module.scss'
import cx from 'classnames'

const PaginaAreasAtuacao = () => (
  <section className={styles.pageCardBgWhite}>
    <section className={styles.headerAreaAtuacao}>
      <div className={styles.text}>
        <span>Áreas de atuação da nossa equipe</span>
      </div>
    </section>
    <section className={styles.cardsContainerAtuacao}>
      <div className={styles.cardRow}>
        <div className={styles.cardAtuacaoContainer}>
          <div className={cx(styles.green, styles.pill)}>
            <span>Transtornos dos sons da fala</span>
          </div>
          <div className={cx(styles.cardAtuacao, styles.greenBorder)}>
            <span>Diagnóstico e tratamento de dificuldades na produção de sons da fala, como substituições, omissões ou distorções, visando melhorar a comunicação oral.</span>
          </div>
        </div>
        <div className={styles.cardAtuacaoContainer}>
          <div className={cx(styles.purple, styles.pill)}>
            <span>Consultoria Fonoaudiológica</span>
          </div>
          <div className={cx(styles.cardAtuacao, styles.purpleBorder)}>
            <span>Orientações para mães de crianças com até três anos que estão enfrentando dificuldades na comunicação inicial, fornecendo suporte em questões relacionadas ao desenvolvimento da linguagem</span>
          </div>
        </div>
        <div className={styles.cardAtuacaoContainer}>
          <div className={cx(styles.green, styles.pill)}>
            <span>Alterações da fluência da fala</span>
          </div>
          <div className={cx(styles.cardAtuacao, styles.greenBorder)}>
            <span>Avaliação e intervenção em distúrbios de fluência, como gagueira e disfluências, para promover uma fala mais fluente e eficaz.</span>
          </div>
        </div>
        <div className={styles.cardAtuacaoContainer}>
          <div className={cx(styles.purple, styles.pill)}>
            <span>Voz</span>
          </div>
          <div className={cx(styles.cardAtuacao, styles.purpleBorder)}>
            <span>Avaliação e tratamento fonoaudiológico para alterações vocais, como rouquidão, cansaço ao falar e dificuldade em projetar a voz com clareza e potência.</span>
          </div>
        </div>
      </div>
      <div className={styles.cardRow}>
        <div className={styles.cardAtuacaoContainer}>
          <div className={cx(styles.green, styles.pill)}>
            <span>Motricidade Orofacial</span>
          </div>
          <div className={cx(styles.cardAtuacao, styles.greenBorder)}>
            <span>Tratamento de problemas na musculatura e estrutura oral, como dificuldades na mastigação, deglutição e articulação dos sons, para melhorar sua funcionalidade e coordenação.</span>
          </div>
        </div>
        <div className={styles.cardAtuacaoContainer}>
          <div className={cx(styles.purple, styles.pill)}>
            <span>Leitura e Escrita</span>
          </div>
          <div className={cx(styles.cardAtuacao, styles.purpleBorder)}>
            <span>Tratamento fonoaudiológico para crianças e adultos com dificuldades na fluência da leitura, compreensão de textos e na elaboração de frases ou textos escritos.</span>
          </div>
        </div>
        <div className={styles.cardAtuacaoContainer}>
          <div className={cx(styles.green, styles.pill)}>
            <span>TPAC</span>
          </div>
          <div className={cx(styles.cardAtuacao, styles.greenBorder)}>
            <span>A terapia do PAC consiste em um conjunto de atividades voltadas para o aprimoramento das habilidades auditivas, com o objetivo de ajudar o cérebro a processar e compreender melhor os sons detectados.</span>
          </div>
        </div>
        <div className={styles.cardAtuacaoContainer}>
          <div className={cx(styles.purple, styles.pill)}>
            <span>Oratória</span>
          </div>
          <div className={cx(styles.cardAtuacao, styles.purpleBorder)}>
            <span>Oferecemos treinamento fonoaudiológico para quem deseja aprimorar suas habilidades comunicativas, abordando aspectos como voz, dicção, linguagem corporal e assertividade na comunicação.</span>
          </div>
        </div>
      </div>
    </section>
  </section>
)

export default PaginaAreasAtuacao