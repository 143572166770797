import styles from './PaginaApresentacaoEmpresa.module.scss'
import imagemFundadoraMinhaFono from '../../assets/images/fundadora-minha-fono.png'

const PaginaApresentacaoEmpresa = () => (
    <section className={styles.pageCard}>
        <div className={styles.primeiroBloco}>
            <img src={imagemFundadoraMinhaFono} alt="" />
            <div className={styles.containerNomeFundadora}>
                <p>Fga. Francis Guimarães</p>
                <p>CRFa 8-12521</p>
                <p>Fundadora da Minha Fono Online</p>
            </div>
        </div>
        <div className={styles.segundoBloco}>
        <p>Para uma melhor comunicação posso contar com a</p>
        <h2>Minha Fono Online</h2>
        <p>Na Minha Fono Online, acreditamos que cada paciente é único. Nossa equipe de fonoaudiólogos trabalha com dedicação para oferecer terapias online personalizadas, atendendo desde crianças em desenvolvimento até adultos que buscam melhorar sua comunicação.</p>
        <p>Com uma equipe experiente e um atendimento 100% online, levamos cuidado, atenção e resultados diretamente para o conforto da sua casa. <br/> Comunicação é essencial e nós estamos aqui para aprimorá-la com você.</p>
        </div>
    </section>
)

export default PaginaApresentacaoEmpresa