import styles from './UpperBar.module.scss'

const UpperBar = () => {
    return (
        <div className={styles.container}>
            <div className={styles.containerItem}></div>
        </div>
    )
}

export default UpperBar