import styles from './Module.module.scss'

type Props = {
    name: string,
    iconName: string
}

const Module = ({name, iconName}: Props) => {
    return (
        <div className={styles.moduleContainer}>
            <i className="material-icons">{iconName}</i>
            <span>{name}</span>
        </div>
    )
}

export default Module