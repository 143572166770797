import styles from './Input.module.scss'

const Input = (props: {
  tipo: string, id?: string, placeholder: string, onChange: any, value: string | number
}) => {
  return (
    <input 
      type={props.tipo} 
      className={styles.input} 
      id={props.id} 
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
    />
  )
}

export default Input